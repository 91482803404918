<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <payments_stat></payments_stat>
      </v-col>
      <v-col cols="12">
        <payments_per_year></payments_per_year>
      </v-col>

      <v-col cols="12">
        <payments_data_grid></payments_data_grid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "payments",
  components: {
    payments_stat: () => import("@/components/dashboard/Stats/payments_stat"),
    payments_data_grid: () =>
      import("@/components/dashboard/DataGrids/payments_data_grid"),
    payments_per_year: () => import("@/components/dashboard/payments_per_year")
  }
};
</script>

<style scoped></style>
